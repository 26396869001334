<template>
  <div class="container">
    <div class="dialogue-box has-header d-flex justify-content-center">
      <div class="dialogue-box-header">Your Listings</div>
      <div class="dialogue-box-inner p-4 px-5 w-100">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th></th>
              <th>Item Name</th>
              <th>Amount</th>
              <th>Price Per Item</th>
              <th>Total Price</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="listing in listings" :key="listing.id" class="align-middle">
              <td>
                <Item :item="listing" :hide-quantity="true"/>
              </td>
              <td>{{ listing.Item.name }} <span v-if="listing.ItemAttributes">{{ listing.ItemAttributes.find(s => s.attribute === 'refineLevel')?.value ? '+' + listing.ItemAttributes.find(s => s.attribute === 'refineLevel')?.value : '' }}</span></td>
              <td>x{{ listing.amount }}</td>
              <td>{{ formatPrice(listing.price) }}</td>
              <td>{{ formatPrice(listing.price * listing.amount) }}</td>
              <th>
                <button @click="cancel(listing)" class="btn btn-danger">Cancel Listing</button>
              </th>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="dialogue-bg"></div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Item from "@/components/items/Item";

export default {
  name: 'MarketplaceListings',
  components: {Item},
  mounted() {
    this.$socket.emit('marketplace:get own listings')
  },
  computed: {
    ...mapGetters({
      listings: "marketplace/your_listings"
    })
  },
  methods: {
    formatPrice(number) {
      return new Intl.NumberFormat('en-gb').format(number);
    },
    cancel(listing) {
      this.$socket.emit('marketplace:cancel listing', {
        id: listing.id
      })
    }
  }
}
</script>